.About {
  padding: 40px;
}

.About h1 {
  text-align: center;
  font-size: 40px;
  line-height: 65px;
}

.About span {
  color: rgb(250, 236, 112);
}

.About p {
  text-align: center;
  margin-bottom: 30px;
  font-size: 18px;
}

.Tech {
  display: flex;
  justify-content: center;
  column-gap: 10px;
  row-gap: 10px;
  width: 100%;
  max-width: 600px;
  flex-wrap: wrap;
  margin: auto;
}

.Tech > div {
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #fff;
}
