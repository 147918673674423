.Work {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Work h2 {
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 8px;
}

.Work h3 {
  margin-top: 8px;
  font-size: 16px;
  color: rgb(201, 197, 197);
}

.Timeline {
  border-left: 3px solid #fff;
  padding: 20px 0 20px 20px;
  max-width: 550px;
}

.Year {
  font-weight: bold;
  font-size: 24px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.Year:first-child {
  margin-top: 0;
}

.Year:last-child {
  margin-bottom: 0;
}

.Tech {
  display: flex;
  column-gap: 10px;
  row-gap: 10px;
  width: 100%;
  flex-wrap: wrap;
  margin: auto;
  margin-bottom: 15px;
}

.Tech > div {
  border-radius: 5px;
  padding: 6px;
  border: 1px solid #fff;
  font-size: 13px;
}
