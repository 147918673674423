.Header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  background-color: rgb(11, 11, 11);
  align-items: center;
  box-sizing: border-box;
}

.Logo {
  border-radius: 100%;
  font-weight: bold;
  font-size: 18px;
  border: 3px solid #fff;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Menu {
  display: flex;
  column-gap: 35px;
  font-size: 15px;
  font-weight: 500;
}

.Menu a {
  color: #fff;
  text-decoration: none;
}
