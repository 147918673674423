.Contacts {
  display: flex;
  justify-content: center;
  font-size: 40px;
  column-gap: 40px;
}

.Contacts a {
  color: #fff;
}
