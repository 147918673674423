body {
  padding: 0;
  margin: 0;
  background-color: black;
  color: white;
}

.App {
  max-width: 1200px;
  width: 100%;
  margin: auto;
  position: relative;
}

h1 {
  text-align: center;
  font-size: 40px;
  line-height: 65px;
}
